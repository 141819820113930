@import '/app/src/shared/theme/mixins.scss'; @import '/app/src/shared/theme/var.scss'; @import '/app/src/theme/var.scss';
.container {
    display: none;

    @include respond-below(phone) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .header {
        display: flex;
        width: 100%;

        .column {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 72px;
            background-color: $theme-color-4;

            .title {
                margin: 0;
                font-weight: 600;
                font-size: 20px;
            }
        }

        .column.altive {
            background-color: $theme-color;
        }
    }

    .section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-top: 16px;
        padding-bottom: 8px;

        &:nth-child(odd) {
            background-color: $theme-color-4;
        }

        &:nth-child(even) {
            background-color: $secondary-color;
        }
    }

    .title {
        @include titleText($theme-color);

        margin-bottom: 12px;
        font-weight: 600;
        font-size: 18px;
        text-align: center;
    }

    .row {
        display: flex;
        width: 100%;
    }

    .column {
        width: 50%;

        &:nth-of-type(1) {
            .content {
                padding: 0 16px;
                padding-bottom: 8px;
                border-right: 1px solid $border-color;
            }
        }

        &:nth-of-type(2) {
            .content {
                margin-left: 12px;
            }
        }
    }

    .content {
        @include contentText($theme-color);

        margin: 0;
        padding: 0 16px;
        padding-bottom: 16px;
        font-weight: normal;
        font-size: 16px;
        line-height: 1.3;

        ul {
            padding-left: 10px;
            list-style-type: none;
        }

        li::before {
            display: inline-block;
            width: 8px;
            height: 8px;
            margin-right: 8px;
            margin-bottom: 2px;
            background-color: $theme-color;
            border-radius: 50%;
            content: ' ';
        }

        p {
            margin: 0;
        }
    }

    .contactButton {
        @include big-button();

        width: 100%;
        margin-top: 24px;
        padding: 0 20px;

        button {
            width: 100%;
        }
    }
}
