@import '/app/src/shared/theme/mixins.scss'; @import '/app/src/shared/theme/var.scss'; @import '/app/src/theme/var.scss';
$column: 12;

@import './var.scss';

@mixin make-col($size, $columns: $grid-columns) {
    flex: 0 0 percentage($size / $columns);
    // Add a `max-width` to ensure content within each column does not blow out
    // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
    // do not appear to require this.
    max-width: percentage($size / $columns);
}

%grid-column {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding-right: 8px;
    padding-left: 8px;
}

.container {
    display: flex;
    flex-wrap: wrap;
    margin-right: -8px;
    margin-left: -8px;
}

// xxs
@for $i from 1 through $column {
    .xxs#{$i} {
        @extend %grid-column;

        @include make-col($i, $column);
    }
}

// xs
@for $i from 1 through $column {
    .xs#{$i} {
        @extend %grid-column;

        @media (min-width: map-get($map: $breakpoints, $key: 'xxs') + 1px) {
            @include make-col($i, $column);
        }
    }
}

// sm
@for $i from 1 through $column {
    .sm#{$i} {
        @extend %grid-column;

        @media (min-width: map-get($map: $breakpoints, $key: 'xs') + 1px) {
            @include make-col($i, $column);
        }
    }
}

// md
@for $i from 1 through $column {
    .md#{$i} {
        @extend %grid-column;

        @media (min-width: map-get($map: $breakpoints, $key: 'sm') + 1px) {
            @include make-col($i, $column);
        }
    }
}

// lg
@for $i from 1 through $column {
    .lg#{$i} {
        @extend %grid-column;

        @media (min-width: map-get($map: $breakpoints, $key: 'md') + 1px) {
            @include make-col($i, $column);
        }
    }
}

// xl
@for $i from 1 through $column {
    .xl#{$i} {
        @extend %grid-column;

        @media (min-width: map-get($map: $breakpoints, $key: 'lg') + 1px) {
            @include make-col($i, $column);
        }
    }
}

// xxl
@for $i from 1 through $column {
    .xxl#{$i} {
        @extend %grid-column;

        @media (min-width: map-get($map: $breakpoints, $key: 'xl') + 1px) {
            @include make-col($i, $column);
        }
    }
}
