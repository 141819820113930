$theme-color: #051c2d !default;
$theme-color-2: #61778b !default;
$theme-color-3: #092538 !default;
$theme-color-4: #e2e6e9 !default;
$theme-color-light: #8395a6 !default;
$theme-color-light-2: #e9f2f7 !default;
$theme-color-light-3: #f8fcff !default;
$theme-color-light-4: #fbfdff !default;

$text-dark: $theme-color !default;

/* stylelint-disable */
:export {
    themeColor: $theme-color;
}
/* stylelint-enable */
